import React from "react"
import Layout from "../components/layout"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import HeroBanner from "../components/herobanner"

function Tagline(props){
    if(props.tagline){
      return <span className="tagline">{props.tagline}</span>
    }else{
      return <></>
    }
}

export default function WorkPost(props) {
    const post = props.data.mdx

    const { previous, next } = props.pageContext;

    return (
      <Layout className="work" pageTitle={post.frontmatter.title} description={post.frontmatter.excerpt}>
        <HeroBanner title={post.frontmatter.title} heroimage={post.frontmatter.heroimage}>

          <h1>{post.frontmatter.title}</h1>
          
          <Tagline tagline={post.frontmatter.tagline}></Tagline>
          <span className="date">posted on {post.frontmatter.date}</span>

        </HeroBanner>
        
        <MDXRenderer>{post.body}</MDXRenderer>

        <nav className="post_navigation">
            {previous && (
              <Link className="prev_link" to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}

            {!previous &&(
              <span></span>
            )}

            {next && (
              <Link className="next_link" to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}

            {!next && (
              <span></span>
            )}
          </nav>
      </Layout>
    )
  }

export const query = graphql `
  query($slug: String!){
    mdx(fields: {slug: {eq: $slug}}) {
      id
      body
      frontmatter {
        title
        thumbnail{
          publicURL
        }
        date
        excerpt
        tagline
        heroimage {
          publicURL
        }
      }
      slug
    }
  }
`